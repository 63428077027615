import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:"/",
    redirect:"/firstView",
  },
  {
    path: '/firstView',
    name: 'FirstView',
    component: () => import(/* webpackChunkName: "about" */ '../views/FirstView')
  },
  {
    path: '/secentView',
    name: 'SecentView',
    component: () => import(/* webpackChunkName: "about" */ '../views/SecentView')
  }
]

const router = new VueRouter({
  routes
})

export default router
