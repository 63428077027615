import Vue from 'vue'
import 'vant/lib/index.css';
import { Button } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Cell, CellGroup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { CountDown } from 'vant';

Vue.use(CountDown);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);